<template>
  <v-container class="pt-0">
    <back-toolbar title="favorites.header"></back-toolbar>
    <item-list
      :items="$store.state.favorites.items"
      v-if="
        $store.state.favorites.items && $store.state.favorites.items.length > 0
      "
    ></item-list>
    <error-image
      v-else
      :text="$t('myPoco.noFavorites')"
      imageName="noFavorites.png"
    ></error-image>
  </v-container>
</template>

<script>
import ItemList from "@/components/recommendations/ItemList";
import BackToolbar from "@/components/app/common/BackToolbar";
import ErrorImage from "@/components/app/common/ErrorImage";
export default {
  components: {
    BackToolbar,
    ItemList,
    ErrorImage,
  },
};
</script>
